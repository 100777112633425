.IconButton {
	position: relative;
	left: 0px;
	width: 48px;
	height: 48px;
	border-radius: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 4px;
	margin-bottom: 4px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.2s ease;
}

.IconButton:hover {
	cursor: pointer;
	background-color: #F7F5FB;
}

.SearchContainer {
	width: 48px;
	position: relative;
	height: 56px;
}