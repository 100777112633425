.Search {
	position: relative;
	left: 0px;
	width: 256px;
	height: 48px;
	border-radius: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 4px;
	margin-bottom: 4px;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.2s ease;
	outline: none;
	border: none;
	padding: 12px 25px;
}

.SearchBar {
	transition: all 0.3s ease 0s;
	width: 50rem;
	font-size: 16px;
	outline: none;
	padding: 12px 25px;
	border-radius: 50px;
	filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.16));
	z-index: 1;
}

.SearchBar:focus {
	filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.32));
}

.SearchBar::placeholder {
}